
	frappe.templates['node_card'] = `<div class="node-card card cursor-pointer" id="{%= id %}" data-parent="{%= parent %}">
	<div class="node-meta d-flex flex-row">
		<div class="mr-3">
			<span class="avatar node-image" title="{{ name }}">
				<span class="avatar-frame" src={{image}} style="background-image: url(\'{%= image %}\')"></span>
			</span>
		</div>
		<div>
			<div class="node-name d-flex flex-row mb-1">
				<span class="ellipsis">{{ name }}</span>
				<div class="btn-xs btn-edit-node d-flex flex-row">
					<a class="node-edit-icon">{{ frappe.utils.icon("edit", "xs") }}</a>
					<span class="edit-chart-node text-xs">{{ __("Edit") }}</span>
				</div>
			</div>
			<div class="node-info d-flex flex-row mb-1">
				<div class="node-title text-muted ellipsis">{{ title }}</div>

				{% if is_mobile %}
					<div class="node-connections text-muted ml-2 ellipsis">
						· {{ connections }} <span class="fa fa-level-down"></span>
					</div>
				{% else %}
					{% if connections == 1 %}
						<div class="node-connections text-muted ml-2 ellipsis">· {{ connections }} Connection</div>
					{% else %}
						<div class="node-connections text-muted ml-2 ellipsis">· {{ connections }} Connections</div>
					{% endif %}
				{% endif %}
			</div>
		</div>
	</div>
</div>
`;
